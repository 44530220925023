import * as React from 'react'
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Obfuscate from 'react-obfuscate'
import ButtonLink from '../components/common/button-link'
import MainCTA from '../components/sections/main-cta'

// import ObfuscateEmail from '../components/common/obfuscate-email'
import Seo from '../components/seo'
import Layout from '../components/layout'
import SocialIcons from '../components/common/social-icons'

import KlaviyoForm from '../components/utilities/klaviyo-form'

const ContactPage = () => {
  let hostname = ''
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    hostname = window.location.hostname
  }
  const isLive = hostname.includes('meati.com')
  const formCode = isLive ? 'TZUzrb' : 'WAC8gi'

  return (
    <Layout>
      <Seo title="Contact" />
      <header className="text-center text-md-start">
        <Container fluid className="px-0">
          <Row>
            <Col md={{ span: 7, order: 1 }} lg="8" xxl="9">
              <Card className="p-2 border-0 px-lg-4 px-xl-6 px-xl py-lg-3 contact-form mx-auto">
                <h1 className="mb-1 mb-md-2 font-swish fw-bold text-primary">Let's talk</h1>
                <p className="lead mb-md-2">
                  We’re here to help and answer any question you might have. We'll do our best to get back to you right away!
                </p>
                <KlaviyoForm code={formCode} />
              </Card>
            </Col>

            <Col md={{ span: 5, order: 0 }} lg="4" xxl="3" className="bg-light text-primary">
              <div className="p-2 p-xl-3 p-xxl-4">
                <h2 className="mb-12">Sell meati™</h2>
                <p className="mb-1">Add deliciously juicy, ultra-tender meati™ cuts to your menu.</p>
                <ButtonLink btnLink="/sell" btnClass="btn-outline-primary mb-2" btnText="Partner With Us" />
                <h2 className="mb-1">Media inquiries</h2>

                <Obfuscate
                  email="press@meati.com"
                  obfuscateChildren={false}
                  linkText="Press inquiries"
                  className="btn btn-outline-primary ad-el-tracked mb-2"
                  data-ad-element-type="cta button"
                  data-ad-text="Press inquiries"
                  data-ad-button-id="version 1"
                  data-ad-button-location="body"
                  headers={{
                    subject: 'Press inquiry from Meati.com',
                  }}
                  aria-label="Press Inquiries"
                >
                  Press Inquiries
                </Obfuscate>
                <h2 className="mb-1">Connect</h2>
                <SocialIcons color="primary" classes="justify-content-center justify-content-sm-start" />
              </div>
            </Col>

          </Row>
        </Container>
      </header>
      <MainCTA />
    </Layout>
  )
}

export default ContactPage
